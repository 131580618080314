@import '@/styles/artifacts.scss';





































































































































.status {
  &-cell h3 {
    display: flex;
    align-items: center;
  }

  &-indicator {
    margin-left: 0.5em;
  }
}
